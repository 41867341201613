<template>
    <ODataLookup :data-object="dsMilestonesLkp" :whereClause="getWhereClause" reloadOnWhereClauseChange> <!-- :contextField="contextField" -->
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
        </template>
        <template #toolbarActions >
            <div class="row">
                <div class="col">
                    <OToolbarAction  :text="$t('Restrict To Context')"  v-model="restrictedToContext" v-if="context.domainId" />
                </div>
                <div class="col">
                    <OToolbarAction class="col" :text="$t('Include Expired')" v-model="includeExpiredMS" />                 
                </div>
            </div>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="200"></OColumn>
        <OColumn field="Title" width="400"></OColumn>
        <OColumn field="MilestoneDate" width="100" format="Short Date"></OColumn>
        <OColumn field="OrgUnit" width="200" ></OColumn>
    </ODataLookup>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
import context from "o365.modules.Context.ts";


const includeExpiredMS = ref(false);
const restrictedToContext = ref(true);
const props = defineProps({
    textInput: Boolean,
    is: String,
    IdPath: String,
    textInputValue: String,
});

const dsMilestonesLkp = getOrCreateDataObject({
    id:"o_dsMilestonesLkp" + crypto.randomUUID(),
    viewName:"aviw_Complete_Milestones",
    loadRecents: true,
    maxRecords:100,
    whereClause: "",
    fields: [
        {name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "NameAndTitle", type: "string" },        
        {name: "Title", type: "string"},
        {name: "MilestoneDate", type: "date",sortOrder: 1, sortDirection: "desc"},
        {name: "Description", type: "string" },
        {name: "OrgUnit", type: "string" },
        {name: "OrgUnitIdPath", type: "string" },    
        {name: "Domain_ID", type: "number" }
    ]
});

    const getWhereClause = computed( () => {
        var vFilter = [];
        console.log(context);
        if (props.IdPath && restrictedToContext.value) {
            vFilter.push("'" + props.IdPath + "' LIKE OrgUnitIdPath + '%'");
        } 

        if (props.IdPath && !restrictedToContext.value && context.domainId) {
            vFilter.push("Domain_ID = " + context.domainId );
        } 


        if (!props.IdPath) {
            vFilter.push("1=2");
        }

        if (!includeExpiredMS.value) {    
            vFilter.push("(MilestoneDate >= GETUTCDATE() OR MilestoneDate IS NULL)");
        }

        return vFilter.join(" AND ");
    });


// const getWhereClause = computed(() => {
    // const clauses = [];

    // if (!includeExpiredMS.value) {    
        // clauses.push("(MilestoneDate >= GETUTCDATE() OR MilestoneDate IS NULL)");
    // }
    
    // return clauses.join(" AND ");
// });

const contextField = computed(() => {
    if (props.IdPath) {
        return () => "'" + props.IdPath + "' LIKE OrgUnitIdPath + '%'";
    }

    return "OrgUnitIdPath";
});


</script>